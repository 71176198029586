import { Datagrid, DateField, EditButton, List, TextField } from "react-admin";
import { ResetDownloadsButton } from "./components";

export const UsersList = () => {
  return (
    <List perPage={25}>
      <Datagrid>
        <TextField source="firstName" />
        <TextField source="email" />
        <TextField source="description" />
        <TextField source="hasWav" />
        <TextField source="socialLinks.youtube" />
        <TextField source="socialLinks.instagram" />
        <TextField source="socialLinks.facebook" />
        <TextField source="socialLinks.tiktok" />
        <TextField source="socialLinks.business" />
        <DateField source="createdAt" showTime />
        <ResetDownloadsButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

import { Datagrid, EditButton, ImageField, List, ShowButton, TextField } from "react-admin";

export const AlbumsList = () => {
  return (
    <List perPage={25}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ImageField source="mediumCoverUrl" />
        <TextField label="Number of tracks" source="numTracks" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

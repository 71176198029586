import { FunctionComponent } from "react";
import { required, SimpleForm, TextInput, useCreateContext, useEditContext, useRedirect } from "react-admin";

interface DealTagsFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const DealTagsFormInputs: FunctionComponent<DealTagsFormInputsProps> = (props: DealTagsFormInputsProps) => {
  const { isCreate, isEdit } = props;
  const { save: saveOnEdit, redirect: editRedirect } = useEditContext();
  const { save: saveOnCreate, redirect: createRedirect } = useCreateContext();
  const redirect = useRedirect();

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await saveOnEdit(data);
        redirect(editRedirect, "deal-tags");
        return;
      }

      if (isCreate) {
        await saveOnCreate(data);
        redirect(createRedirect, "deal-tags");

        return;
      }
    } catch {}
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput validate={required()} source="name" />
    </SimpleForm>
  );
};

import { ArrayField, ChipField, ImageField, Show, SimpleShowLayout, SingleFieldList, TextField } from "react-admin";

export const AlbumShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <ImageField source="mediumCoverUrl" />
        <ArrayField source="tracks">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

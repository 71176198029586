import { Stack } from "@mui/material";
import isUrl from "is-url";
import { FunctionComponent, useMemo } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FunctionField,
  Labeled,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreateContext,
  useEditContext,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { REFERENCE_ARRAY_INPUT_SX } from "../../constants";
import { TrackFormAlbumPositionInput } from "./TrackFormAlbumPositionInput";

const validateUrl = (value) => {
  if (!value || isUrl(value)) {
    return undefined;
  }

  return "Url is not valid. Example: https://google.com";
};

interface TrackFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const TrackFormInputs: FunctionComponent<TrackFormInputsProps> = (props: TrackFormInputsProps) => {
  const { isCreate, isEdit } = props;

  const { save: saveOnEdit, redirect: editRedirect } = useEditContext();
  const { save: saveOnCreate, redirect: createRedirect } = useCreateContext();
  const redirect = useRedirect();

  const record = useRecordContext();

  const onSubmit = async (data) => {
    if (data.tags) {
      data.tags = data.tags.map((el) => el.tagId).flat();
    }
    try {
      if (isEdit) {
        await saveOnEdit(data);
        redirect(editRedirect, "tracks");

        return;
      }

      if (isCreate) {
        await saveOnCreate(data);
        redirect(createRedirect, "tracks");
        return;
      }
    } catch {}
  };

  const transformedRecord = useMemo((): unknown => {
    if (!record) {
      return;
    }

    if (!record?.tags?.length || !record.tags[0]._id) {
      return;
    }

    const tags = (record?.tags || []).reduce((res, el) => {
      const existingGroup = res.find((group) => group.tagTypeId === el.tagTypeId._id);

      if (existingGroup) {
        existingGroup.tagId.push(el._id);
      } else {
        res.push({
          tagId: [el._id],
          tagTypeId: el.tagTypeId._id,
        });
      }

      return res;
    }, []);

    return { ...record, tags };
  }, [record]);

  return (
    <SimpleForm onSubmit={onSubmit} record={transformedRecord}>
      <TextInput source="name" validate={required()} />
      <ReferenceInput source="composerId" reference="composers">
        <AutocompleteInput validate={required()} />
      </ReferenceInput>
      <NumberInput source="length" disabled />
      <NumberInput source="bpm" />
      <TextInput source="videoUrl" validate={validateUrl} />
      <TextInput source="spotifyUrl" validate={validateUrl} />
      <TextInput source="soundCloudUrl" validate={validateUrl} />
      <TextInput source="bandCampUrl" validate={validateUrl} />
      <TextInput source="appleMusicUrl" validate={validateUrl} />
      {record && (
        <Labeled>
          <FunctionField
            label="Track"
            render={(localRecord) => {
              if (!localRecord.fileUrl) {
                return null;
              }

              return <audio controls src={localRecord.lowQualityFileUrl} preload="none" />;
            }}
          />
        </Labeled>
      )}
      <FileInput accept=".wav" source="file" label="WAV" validate={isCreate && required()}>
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput accept=".mp3" source="lowQualityFile" label="MP3" validate={isCreate && required()}>
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput accept=".ogg" source="streamingFile" label="OGG" validate={isCreate && required()}>
        <FileField source="src" title="title" />
      </FileInput>
      <Stack direction="row" gap="8px">
        <ReferenceInput source="albumId" reference="albums">
          <AutocompleteInput validate={isCreate && required()} />
        </ReferenceInput>
        <TrackFormAlbumPositionInput isCreate={isCreate} isEdit={isEdit} />
      </Stack>
      <ArrayInput source="tags" validate={required()}>
        <SimpleFormIterator disableReordering inline>
          <ReferenceInput source="tagTypeId" reference="tag-types">
            <AutocompleteInput
              optionText={(el) => el.name}
              filterToQuery={(q) => ({ name: q })}
              isRequired
              validate={required()}
              sx={REFERENCE_ARRAY_INPUT_SX}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({
              // The data for this item of the ArrayInput
              scopedFormData,
              getSource,
              formData,
              ...rest
            }) => {
              return (
                <ReferenceArrayInput
                  source={getSource("tagId")}
                  reference="tags"
                  filter={{ tagTypeIds: [scopedFormData.tagTypeId] }}
                  {...rest}
                >
                  <AutocompleteArrayInput
                    validate={required()}
                    optionText={(el) => el.value}
                    filterToQuery={(q) => ({ value: q })}
                    isRequired
                    sx={REFERENCE_ARRAY_INPUT_SX}
                  />
                </ReferenceArrayInput>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

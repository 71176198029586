import { FC } from "react";
import { NumberInput, required, useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";

interface TrackFormAlbumPositionInputProps {
  isCreate?: boolean;
  isEdit?: boolean;
}

export const TrackFormAlbumPositionInput: FC<TrackFormAlbumPositionInputProps> = ({ isCreate, isEdit }) => {
  const albumId = useWatch<{ albumId: string }>({ name: "albumId" });
  const record = useRecordContext();

  const isRequiredOnEdit =
    isEdit && albumId && albumId !== record.albumId && required("Album position is required with changed album");
  const isRequiredOnCreate = isCreate && required();

  return (
    <NumberInput min={1} source="albumPosition" disabled={!albumId} validate={isRequiredOnCreate || isRequiredOnEdit} />
  );
};
